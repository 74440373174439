<template>
    <div>
        <b-col class="mb-3">
            <label><b>{{ $t('request_type') }}</b></label>
            <b-form-input readonly v-model="form.request_type_name">{{form.request_type_name??'-'}}</b-form-input>
        </b-col>

        <b-col  class="mb-3">
            <label><b>{{ $t('explanation') }}</b></label>
            <b-form-input readonly v-model="form.explanation"></b-form-input>
        </b-col>
        <b-col  class="mb-3">
            <label><b>{{ $t('status') }}</b></label>
            <b-form-input readonly v-model="form.status"></b-form-input>
        </b-col>
        <b-col cols="6" >
            <b-form-group :label="$t('file')" v-if="form.file">
                <b-input-group>
                    <b-form-input
                        :disabled="true"
                        v-model="form.file.file_name"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button variant="primary" @click="downloadFile" v-b-tooltip.hover title="Download Document">
                            <i class="ri-arrow-down-line"></i>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>
    </div>
</template>
<script>
import HousingRequestListServices from "@/services/HousingRequestListServices";
import StudentProgramService from "@/services/StudentProgramService";
import {EventBus} from "@/main";
export default {
    props:{
      formId:{}
    },
    components: {
    },
    data() {
        return {
            form:{
                request_type:null,
                status:null,
                files:null,
                explanation:null,
            }
        }
    },
    created() {
      this.getData()
    },
    computed:{
        getReservationToken(){
            return this.$store.getters['roomReservationSystem/getReservationToken']
        }
    },
    methods: {
        downloadFile(){
            StudentProgramService.downloadFile(this.formData.file.uuid)
                .then(response => {
                    if (response.headers['content-type'] === 'application/pdf') {
                        let fileName = this.formData.file.file_name
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                    } else {
                        this._downloadFile(response,this.formData.file.name)
                    }
                }).catch(err => {
                this.showErrors(err)
            })
        },
       async getData(){
           const config={
               headers: {
                   authorization: `Bearer ${this.getReservationToken}`
               }

           }
             HousingRequestListServices.show(this.formId,config)
                 .then(res => {
                     let data = res.data.data;
                     this.form={
                         request_type_name:data.request_type_name,
                         status:data.status,
                         files:data.files,
                         explanation:data.explanation??'-',
                     }
                 }).catch(error => {
                     this.showErrors(error)
             })
       }
    }
}
</script>
