<template >
    <div>
        <div v-if="!isTenant">
            <app-layout>
                <template v-slot:header>
                    <Header :title="$t('housing_request_list')"
                            @filter-div-status="datatable.filterStatus = $event">
                        <template v-slot:columns>
                            <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                                <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                                 :unchecked-value="true" v-model="column.hidden"
                                                 v-if="column.field != 'buttons'">
                                    {{ column.label }}
                                </b-form-checkbox>
                            </div>
                        </template>
                    </Header>
                </template>
                <template v-slot:header-mobile>
                    <HeaderMobile :title="$t('housing_request_list')"
                                  @filter-div-status="datatable.filterStatus = $event">
                        <template v-slot:columns>
                            <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                                <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                                 :unchecked-value="true" v-model="column.hidden"
                                                 v-if="column.field != 'buttons'">
                                    {{ column.label }}
                                </b-form-checkbox>
                            </div>
                        </template>
                    </HeaderMobile>
                </template>
                <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                           :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                           @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                           v-show="datatable.showTable">
                </datatable>

            </app-layout>
        </div>
        <div v-else class="m-4">
            <TenantHeader/>
            <div class="table-responsive">
                <datatable
                    :isLoading.sync="datatable.isLoading"
                    :columns="datatable.columns"
                    :rows="datatable.rows"
                    :total="datatable.total"
                    :queryParams="datatable.queryParams"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    v-show="datatable.showTable"
                />
            </div>
        </div>
        <CommonModal ref="requestListFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                {{ $t('request_form').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ShowRequestList v-if="formProcess === 'request-list-form'" :formId="formId"/>
            </template>
        </CommonModal>
    </div>

</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout.vue"
import Header from "@/layouts/AppLayout/Header.vue"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"
// Components
import Datatable from "@/components/datatable/Datatable.vue";
import HousingRequestListServices from "@/services/HousingRequestListServices";
import TenantHeader from "@/modules/tenantReservationsList/pages/TeanatHeader.vue"
import ShowRequestList from "@/modules/housingRequestList/pages/ShowRequestList.vue";
export default {
    components: {
        ShowRequestList,
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        TenantHeader
    },
    metaInfo() {
        return {
            title: this.$t('housing_request_list')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show_request_form'),
                                class: 'ri-edit-box-line',
                                callback: (row) => {
                                    this.requestFormListShow(row)
                                },

                            },
                        ]
                    },
                    {
                        label: this.toUpperCase('request_type'),
                        field: 'request_type_name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('explanation'),
                        field: 'explanation',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            form: {},
        }
    },
    created() {
        this.getToken()
    },
    computed: {
      isTenant(){
          return !!localStorage.getItem("tenant_user")
      }
    },

    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows(token) {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            };
            return HousingRequestListServices.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        async getToken(){
            const token = JSON.parse(localStorage.getItem('token'))??null
            if (!token){
                await this.$store.dispatch('roomReservationSystem/setReservationToken');
                const newToken=JSON.parse(localStorage.getItem('token'));
                await this.getRows(newToken)
            }else{
                await this.getRows(token)
            }
        },
        requestFormListShow(row) {
            this.formId = row.id
            this.formData=row
            this.formProcess = 'request-list-form'
            this.$refs.requestListFormModal.$refs.commonModal.show()
        },

    }
}
</script>

